import React from 'react'

export default function Info4() {
  return (
    <section id='info4' className="services-section py-16 md:py-24 bg-base-200">
      <div className="container mx-auto  md:px-8">
        <div className="text-center mb-12 md:mb-16">
          <h1 className="h1-info4 text-3xl md:text-4xl lg:text-5xl font-bold piel2">
          Tecnologías Clave para una Ciudad Digital
          </h1>
        </div>
        <div className='grid grid-cols-1 md:grid-cols-2 '>
        <div className="div-info4 grid grid-cols-1 md:grid-cols-2 max-w-7xl mx-auto fade-in-on-scroll margin-iz">
          
        <div style={{justifySelf:'center', textAlign:'left'}} className="card bg-no  w-full">
            <div className="flex">
                
                <div>
                <svg class="colorable-icon" viewBox="0 0 640 512" xmlns="http://www.w3.org/2000/svg" data-icon="sensor-triangle-exclamation" data-prefix="fal" aria-hidden="true"><path d="M64 64H384c17.7 0 32 14.3 32 32v39.4c9.8-4.8 20.7-7.4 32-7.4V96c0-35.3-28.7-64-64-64H64C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H225c-.6-4-1-8.1-1-12.3c0-6.7 .9-13.3 2.6-19.7H64c-17.7 0-32-14.3-32-32V96c0-17.7 14.3-32 32-32zm64 80c0-8.8-7.2-16-16-16s-16 7.2-16 16V272c0 8.8 7.2 16 16 16s16-7.2 16-16V144zm64 0c0-8.8-7.2-16-16-16s-16 7.2-16 16V272c0 8.8 7.2 16 16 16s16-7.2 16-16V144zm291.8 36.9C476.5 168 462.8 160 448 160s-28.5 8-35.8 20.9l-150.4 265c-3.8 6.7-5.8 14.2-5.8 21.9c0 24.5 19.8 44.3 44.3 44.3H595.7c24.5 0 44.3-19.8 44.3-44.3c0-7.7-2-15.2-5.8-21.9l-150.4-265zM440 196.7c1.6-2.9 4.7-4.7 8-4.7s6.4 1.8 8 4.7l150.4 265c1 1.9 1.6 3.9 1.6 6.1c0 6.8-5.5 12.3-12.3 12.3H300.3c-6.8 0-12.3-5.5-12.3-12.3c0-2.1 .6-4.2 1.6-6.1L440 196.7zM464 304c0-8.8-7.2-16-16-16s-16 7.2-16 16v64c0 8.8 7.2 16 16 16s16-7.2 16-16V304zM448 448a24 24 0 1 0 0-48 24 24 0 1 0 0 48z" fill="currentColor"></path></svg>
              <h2 className="font-bold  piel">Sensores</h2>
              <p className='nobile piel2'>Recopilación de datos en tiempo real sobre el tráfico, la calidad del aire y otros aspectos de la ciudad.</p>
              </div>
            
            </div>
          </div>

          <div style={{justifySelf:'center', textAlign:'left'}} className="card bg-no  w-full">
            <div className="flex">
                
                <div>
                <svg class="colorable-icon" viewBox="0 0 640 512" xmlns="http://www.w3.org/2000/svg" data-icon="cloud" data-prefix="fal" aria-hidden="true"><path d="M429.7 135.7c10.3-4.9 21.9-7.7 34.3-7.7c44.2 0 80 35.8 80 80c0 7.7-1.1 15-3 22c-4.1 14.5 2.5 30 15.8 37.1C587.3 283.2 608 315.2 608 352c0 53-43 96-96 96H144C82.1 448 32 397.9 32 336c0-48.8 31.2-90.3 74.8-105.7c12.3-4.3 20.8-15.7 21.3-28.8C131.5 125 194.6 64 272 64c48.7 0 91.7 24.1 117.8 61.2c9 12.7 25.8 17.2 39.9 10.5zM416 106.8C384.1 61.5 331.5 32 272 32c-94.6 0-171.7 74.6-175.8 168.1C40.2 219.9 0 273.2 0 336c0 79.5 64.5 144 144 144H512c70.7 0 128-57.3 128-128c0-49.1-27.7-91.8-68.3-113.2c2.8-9.8 4.3-20.1 4.3-30.8c0-61.9-50.1-112-112-112c-17.2 0-33.5 3.9-48 10.8z" fill="currentColor"></path></svg>
              <h2 className="font-bold  piel">Nube</h2>
              <p className='nobile piel2'>Almacenamiento y procesamiento de datos, permitiendo la gestión de servicios digitales y la toma de decisiones informadas.</p>
              </div>
            
            </div>
          </div>

          <div style={{justifySelf:'center', textAlign:'left'}} className="card bg-no  w-full">
            <div className="flex">
                
                <div>
                <svg class="colorable-icon" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg" data-icon="globe" data-prefix="fal" aria-hidden="true"><path d="M256 480c16.7 0 40.4-14.4 61.9-57.3c9.9-19.8 18.2-43.7 24.1-70.7H170c5.9 27 14.2 50.9 24.1 70.7C215.6 465.6 239.3 480 256 480zM164.3 320H347.7c2.8-20.2 4.3-41.7 4.3-64s-1.5-43.8-4.3-64H164.3c-2.8 20.2-4.3 41.7-4.3 64s1.5 43.8 4.3 64zM170 160H342c-5.9-27-14.2-50.9-24.1-70.7C296.4 46.4 272.7 32 256 32s-40.4 14.4-61.9 57.3C184.2 109.1 175.9 133 170 160zm210 32c2.6 20.5 4 41.9 4 64s-1.4 43.5-4 64h90.8c6-20.3 9.3-41.8 9.3-64s-3.2-43.7-9.3-64H380zm78.5-32c-25.9-54.5-73.1-96.9-130.9-116.3c21 28.3 37.6 68.8 47.2 116.3h83.8zm-321.1 0c9.6-47.6 26.2-88 47.2-116.3C126.7 63.1 79.4 105.5 53.6 160h83.7zm-96 32c-6 20.3-9.3 41.8-9.3 64s3.2 43.7 9.3 64H132c-2.6-20.5-4-41.9-4-64s1.4-43.5 4-64H41.3zM327.5 468.3c57.8-19.5 105-61.8 130.9-116.3H374.7c-9.6 47.6-26.2 88-47.2 116.3zm-143 0c-21-28.3-37.5-68.8-47.2-116.3H53.6c25.9 54.5 73.1 96.9 130.9 116.3zM256 512A256 256 0 1 1 256 0a256 256 0 1 1 0 512z" fill="currentColor"></path></svg>
              <h2 className="font-bold  piel">Internet de las Cosas (IoT)</h2>
              <p className='nobile piel2'>Conexión de dispositivos inteligentes para mejorar la eficiencia y la gestión de recursos.</p>
              </div>
            
            </div>
          </div>

          <div style={{justifySelf:'center', textAlign:'left'}} className="card bg-no  w-full">
            <div className="flex">
                
                <div>
                <svg class="colorable-icon" viewBox="0 0 384 512" xmlns="http://www.w3.org/2000/svg" data-icon="file-chart-column" data-prefix="fal" aria-hidden="true"><path d="M320 480H64c-17.7 0-32-14.3-32-32V64c0-17.7 14.3-32 32-32H192V144c0 26.5 21.5 48 48 48H352V448c0 17.7-14.3 32-32 32zM240 160c-8.8 0-16-7.2-16-16V32.5c2.8 .7 5.4 2.1 7.4 4.2L347.3 152.6c2.1 2.1 3.5 4.6 4.2 7.4H240zM64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V163.9c0-12.7-5.1-24.9-14.1-33.9L254.1 14.1c-9-9-21.2-14.1-33.9-14.1H64zM208 240c0-8.8-7.2-16-16-16s-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V240zm96 64c0-8.8-7.2-16-16-16s-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V304zM112 368c0-8.8-7.2-16-16-16s-16 7.2-16 16v64c0 8.8 7.2 16 16 16s16-7.2 16-16V368z" fill="currentColor"></path></svg>
              <h2 className="font-bold  piel">Análisis de Datos</h2>
              <p className='nobile piel2'>Identificación de patrones y tendencias para optimizar la gestión urbana y la toma de decisiones.</p>
              </div>
            
            </div>
          </div>


          

          

          
          

          
        </div>
        <div className='grid grid-cols-1 md:grid-cols-1'>
            <img className='img-fn' src='img/9.webp' />
            
        </div>
        </div>
      </div>
    </section>
  )
}
