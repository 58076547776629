import React from 'react'

export default function Info2() {
  return (
    <section id='servicios' className="services-section py-16 md:py-24 bg-base-200">
      <div className="container mx-auto  md:px-8">
        <div className="text-center mb-12 md:mb-16">
          <h1 style={{textAlign:'center'}} className="text-3xl md:text-4xl lg:text-5xl font-bold piel2">
          Ejemplos de Ciudades Digitales Exitosas
          </h1>
        </div>
        
        <div style={{alignItems:'baseline'}} className=" ge grid grid-cols-1 md:grid-cols-3 max-w-7xl mx-auto fade-in-on-scroll">
          
          <div style={{justifySelf:'center'}} className="card2   w-full">
            <div className="card-body">
                <img src='img/4.webp' />
              <h2 className="titulo piel">Singapur</h2>
              <p className='piel2'>Famosa por su transporte público inteligente y su enfoque en la sostenibilidad.</p>
           
            
            </div>
          </div>

          <div style={{justifySelf:'center'}} className="card2   w-full">
            <div className="card-body">
                <img src='img/3.webp' />
              <h2 className="titulo piel">Amsterdam</h2>
              <p className='piel2'>Líder en movilidad urbana sostenible, con un sistema de bicicletas compartido y una gestión inteligente del tráfico.</p>
            
            
            </div>
          </div>

          <div style={{justifySelf:'center'}} className="card2   w-full">
            <div className="card-body">
                <img src='img/6.webp' />
              <h2 className="titulo piel">Barcelona</h2>
              <p className='piel2'>Se destaca por su uso de la tecnología para mejorar el turismo y la gestión de los servicios públicos.</p>
          
            
            </div>
          </div>

       

          

          

          
          

          
        </div>
        
      </div>
    </section>
  )
}
