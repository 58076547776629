import React from 'react'

export default function Servicios() {
  return (
    <section id='servicios' className="services-section py-16 md:py-24 bg-base-200">
      <div className="container mx-auto  md:px-8">
        <div className="text-center mb-12 md:mb-16">
          <h1 style={{textAlign:'center'}} className="text-3xl md:text-4xl lg:text-5xl font-bold piel2">
          ¿Qué es una Ciudad Digital?
          </h1>
        </div>
        <div>
        <div className="serv grid grid-cols-1 md:grid-cols-2 gap-6 md:gap-8 max-w-7xl mx-auto fade-in-on-scroll">
          
        <div className="grid grid-cols-1  fade-in-on-scroll">
          <h2 className="card-title piel">Conectada</h2>
          <p className='piel2'>Una ciudad digital es un entorno donde la tecnología se integra en todos los aspectos de la vida urbana, desde el transporte hasta la gestión de recursos.</p>
          
          
          </div>

          <div className="grid grid-cols-1  fade-in-on-scroll">
          <h2 className="card-title piel">Inteligente</h2>
          <p className='piel2'>Se caracteriza por la aplicación de soluciones digitales para mejorar la eficiencia, la sostenibilidad y la calidad de vida de los ciudadanos.</p>
          
          
          </div>

          
          

          
        </div>
        </div>
        
      </div>
    </section>
  )
}
