import React, { useState } from 'react';
import { db } from '../firebase';
import { collection, addDoc, serverTimestamp } from 'firebase/firestore';

export default function Contacto() {
  const [formData, setFormData] = useState({
    nombre: '',
    email: '',
    mensaje: ''
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitStatus, setSubmitStatus] = useState({ type: '', message: '' });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setSubmitStatus({ type: '', message: '' });

    try {
      const contactData = {
        ...formData,
        createdAt: serverTimestamp()
      };

      const docRef = await addDoc(collection(db, 'contacts_busia'), contactData);
      console.log('Documento creado con ID:', docRef.id);

      setFormData({ nombre: '', email: '', mensaje: '' });
      setSubmitStatus({
        type: 'success',
        message: '¡Gracias por contactarnos! Te responderemos pronto.'
      });
    } catch (error) {
      console.error('Error al enviar el formulario:', error);
      setSubmitStatus({
        type: 'error',
        message: 'Hubo un error al enviar el mensaje. Por favor, intenta nuevamente.'
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div id='contacto'>
      <div className="hero bg-base-200 min-h-screen fade-in-on-scroll px-4 md:px-8 contacto-bg">
        <div className="container mx-auto py-8 md:py-16">
          <h1 className="text-3xl md:text-5xl font-bold text-center mb-8 md:mb-16 piel2">Contacto</h1>

          <div className="hero-content flex-col lg:flex-row-reverse gap-8 justify-center items-center max-w-6xl mx-auto">
            <div className="text-center w-full lg:w-1/2 flex justify-center items-center">
              <img
                src="img/13.webp"
                className="rounded-lg shadow-2xl w-full h-64 md:h-96 object-cover"
                alt="Contacto" />
            </div>

            <div style={{backgroundColor:'#071D42'}} className="card bg-base-100 shadow-2xl w-full lg:w-1/2">
              <div className="text-center p-6">
                <h2  className="text-xl md:text-2xl font-bold piel">Escríbenos</h2>
                <hr className="solid separador my-4" />
                <div className='escribenos space-y-4'>
                  <div className="flex items-center justify-center gap-2">
                  <svg xmlns="http://www.w3.org/2000/svg" class="colorable-icon2" viewBox="0 0 24 24">
        <path d="M20 4H4C2.89 4 2 4.89 2 6V18C2 19.1 2.89 20 4 20H20C21.1 20 22 19.1 22 18V6C22 4.89 21.1 4 20 4ZM20 8L12 13L4 8V6L12 11L20 6V8Z" />
    </svg>
                    <span className="text-sm md:text-base">Email: mauricio@iwie.space</span>
                  </div>
                  <div className="flex items-center justify-center gap-2">
                  <svg xmlns="http://www.w3.org/2000/svg" class="colorable-icon2" viewBox="0 0 24 24">
        <path d="M6.62 10.79C8.06 13.97 10.03 15.94 13.21 17.38L15 15.59C15.27 15.32 15.67 15.23 16.03 15.33C17.27 15.7 18.65 15.95 20.06 15.95C20.6 15.95 21 16.35 21 16.89V20.03C21 20.57 20.6 20.97 20.06 20.97C10.87 20.97 3.03 13.13 3.03 3.94C3.03 3.4 3.43 3 3.97 3H7.11C7.65 3 8.05 3.4 8.05 3.94C8.05 5.35 8.3 6.73 8.67 7.97C8.76 8.33 8.68 8.73 8.41 9L6.62 10.79Z" />
    </svg>
                    <span className="text-sm md:text-base">Teléfono: +56 9 5810 8312</span>
                  </div>
                </div>
              </div>

              <form onSubmit={handleSubmit} className="card-body">
                {submitStatus.message && (
                  <div className={`alert ${submitStatus.type === 'success' ? 'alert-success' : 'alert-error'} mb-6`}>
                    <span>{submitStatus.message}</span>
                  </div>
                )}

                <div className="form-control">
                  <span className="label">
                    <span className="label-text">Nombre</span>
                  </span>
                  <label className="input input-bordered flex items-center gap-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 16 16"
                      fill="currentColor"
                      className="h-4 w-4 opacity-70">
                      <path
                        d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6ZM12.735 14c.618 0 1.093-.561.872-1.139a6.002 6.002 0 0 0-11.215 0c-.22.578.254 1.139.872 1.139h9.47Z" />
                    </svg>
                    <input 
                      type="text" 
                      className="grow" 
                      placeholder="Tu nombre" 
                      name="nombre"
                      value={formData.nombre}
                      onChange={handleChange}
                      required
                      disabled={isSubmitting}
                    />
                  </label>
                </div>

                <div className="form-control">
                  <span className="label">
                    <span className="label-text">Email</span>
                  </span>
                  <label className="input input-bordered flex items-center gap-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 16 16"
                      fill="currentColor"
                      className="h-4 w-4 opacity-70">
                      <path
                        d="M2.5 3A1.5 1.5 0 0 0 1 4.5v.793c.026.009.051.02.076.032L7.674 8.51c.206.1.446.1.652 0l6.598-3.185A.755.755 0 0 1 15 5.293V4.5A1.5 1.5 0 0 0 13.5 3h-11Z" />
                      <path
                        d="M15 6.954 8.978 9.86a2.25 2.25 0 0 1-1.956 0L1 6.954V11.5A1.5 1.5 0 0 0 2.5 13h11a1.5 1.5 0 0 0 1.5-1.5V6.954Z" />
                    </svg>
                    <input 
                      type="email" 
                      className="grow" 
                      placeholder="tucorreo@tudominio" 
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      required
                      disabled={isSubmitting}
                    />
                  </label>
                </div>

                <div className="form-control">
                  <span className="form-control">
                    <div className="label">
                      <span className="label-text">Mensaje</span>
                    </div>
                    <textarea 
                      className="textarea textarea-bordered h-32" 
                      placeholder="Escríbenos tu mensaje"
                      name="mensaje"
                      value={formData.mensaje}
                      onChange={handleChange}
                      required
                      disabled={isSubmitting}
                    ></textarea>
                  </span>
                </div>

                <div className="form-control mt-6">
                  <button style={{color: 'white'}}
                    className={`btn btn-primary w-full md:w-auto ${isSubmitting ? 'loading' : ''}`}
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? 'Enviando...' : 'Enviar'}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
