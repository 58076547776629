import React from 'react'

export default function Info3() {
  return (
    <section id='servicios' className="info3-section py-16 md:py-24 bg-base-200">
      <div className="container mx-auto  md:px-8">
        <div className="text-center mb-12 md:mb-16">
          <h1 style={{textAlign:'center'}} className="text-3xl md:text-4xl lg:text-5xl font-bold piel2">
          Pasos para Digitalizar una Ciudad
          </h1>
        </div>
        
        <div style={{alignItems:'baseline', gap:'2rem 0rem'}} className="grid grid-cols-1 md:grid-cols-3 max-w-7xl mx-auto fade-in-on-scroll">
          
          <div style={{justifySelf:'center', textAlign:'center'}} className="card  w-full">
            <div className="card-body">
                
              <h2 className="font-bold titulo piel">Planificación</h2>
              <p className='nobile piel2'>Definir objetivos, estrategias y recursos.</p>
           
            
            </div>
          </div>

          <div style={{justifySelf:'center', textAlign:'center'}} className="card  w-full">
            <div className="card-body">
                
              <h2 className="font-bold titulo piel">Implementación</h2>
              <p className='nobile piel2'>Instalar infraestructura y soluciones digitales.</p>
            
            
            </div>
          </div>

          <div style={{justifySelf:'center', textAlign:'center'}} className="card   w-full">
            <div className="card-body">
                
              <h2 className="font-bold titulo piel">Integración</h2>
              <p className='nobile piel2'>Conectar sistemas y plataformas para una gestión eficiente.</p>
          
            
            </div>
          </div>

         


          

          

          
          

          
        </div>
        
      </div>
    </section>
  )
}
