import React from 'react'
import { Link } from "react-scroll";

export default function Hero1() {
  return (
    <section id='inicio' className='hero-section'>
      <div className="min-h-screen bg-hero-pattern">
        <div className="overlay min-h-screen flex items-center justify-center">
          <div className="content-wrapper text-center px-4 md:px-8 py-16 fade-in">
            <div className="mx-auto" style={{marginTop:'-5rem'}}>
              <div className='GEI' style={{display:'flex', justifyContent:'center',marginBottom:'1rem'}}>
              Ciudad Digital
              </div>
              
              <h1 style={{fontSize:'3rem'}} className=" piel font-bold  mb-6">
              Hacia una Vida Más Inteligente             </h1>
              {/* <h2 style={{marginBottom:'4rem'}} className=" nobile mb-6">
              Nuestra plataforma utiliza inteligencia artificial avanzada para ayudarte a gestionar tu impacto ambiental de manera inteligente y efectiva.
              </h2> */}
              <div className="div-btn-hero">
              
              <Link style={{fontSize:'1.5rem'}}
                to="servicios"
                smooth={true}
                duration={500}
                offset={-70}
                className="btn btn-contacto w-full md:w-auto cursor-pointer verde"
              >
                Saber más
              </Link>
              </div>
             
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
