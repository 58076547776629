import React from 'react'
// import { Link } from 'react-router-dom'
import { Link } from "react-scroll";

export default function Header() {
  return (
    <div  className="navbar bg-base-100 fixed top-0 z-50">
      <div className="navbar-start">
        <div className="dropdown">
          <div tabIndex={0} role="button" className="btn btn-ghost lg:hidden verde">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h8m-8 6h16" />
            </svg>
          </div>
          <ul  tabIndex={0} className="menu menu-sm dropdown-content mt-3 z-[1] p-2 shadow bg-base-100 rounded-box w-52">
          <li><Link to="nosotros" smooth={true} duration={500}>Información</Link></li>
            <li><Link to="info4" smooth={true} duration={500}>Tecnologías</Link></li>
            <li><Link to="contacto" smooth={true} duration={500}>Contacto</Link></li>
          </ul>
        </div>
        <img style={{width:'3rem'}} src='favicon.ico'/>
        
        <Link smooth={true} duration={500} to="inicio" className="btn btn-ghost text-xl verde">Ciudad Digital</Link>
      </div>
      
      <div className="navbar-center hidden lg:flex">
        <ul className="menu menu-horizontal px-1 verde">
            <li><Link to="nosotros" smooth={true} duration={500}>Información</Link></li>
            <li><Link to="info4" smooth={true} duration={500}>Tecnologías</Link></li>
            <li><Link to="contacto" smooth={true} duration={500}>Contacto</Link></li>
        </ul>
      </div>

      <div className="navbar-end">
        {/* <button className="btn btn-primary">
          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" viewBox="0 0 20 20" fill="currentColor">
            <path fillRule="evenodd" d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z" clipRule="evenodd" />
          </svg>
          Iniciar Sesión
        </button> */}
      </div>
    </div>
  )
}
