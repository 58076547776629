import React from 'react'

export default function Info1() {
  return (
    <section id='nosotros' className="info1-section py-16 md:py-24 bg-base-200">
      <div className="container mx-auto  md:px-8">
       
        <div className='card shadow-2xl grid grid-cols-1 md:grid-cols-1 gap-6 md:gap-8 max-w-7xl mx-auto fade-in-on-scroll'>
        <h1 className="h1-info1 text-3xl md:text-4xl lg:text-5xl font-bold piel2">
        Beneficios de una Ciudad Digital
          </h1>
        <div style={{alignItems:'baseline'}} className="card grid grid-cols-1 md:grid-cols-3 max-w-7xl mx-auto fade-in-on-scroll">
          
          <div style={{justifySelf:'end'}} className="card w-full">
            <div className="card-body">
              <h2 className="card-title piel">Eficiencia</h2>
              <p className='piel2 nobile'>Optimización de los procesos urbanos, reducción de gastos y mayor productividad.</p>
           
            
            </div>
          </div>

          <div style={{justifySelf:'end'}} className="card w-full">
            <div className="card-body">
              <h2 className="card-title piel">El Problema del Aumento</h2>
              <p className='piel2 nobile'>Gestión eficiente de los recursos, reducción de la contaminación y promoción de prácticas eco-amigables.</p>
           
            
            </div>
          </div>

          <div style={{justifySelf:'end'}} className="card w-full">
            <div className="card-body">
              <h2 className="card-title piel">Calidad de Vida</h2>
              <p className='piel2 nobile'>Mejor acceso a servicios públicos, mayor seguridad, mejores oportunidades de empleo y una vida más cómoda.</p>
            
            
            </div>
          </div>


          </div>

          

          
          

          
        </div>
        
      </div>
    </section>
  )
}
